<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <div>
        <q-b-header></q-b-header>
        <v-card color="#f2f3f7">
          <v-row>
            <v-card-text>
              <v-row>
                <v-col md="4" sm="1"></v-col>
                <v-col>
                  <v-text-field
                      v-model="words"
                      @input="search()"
                      outlined
                      placeholder="Write some words"
                      label="Search"
                      color="black"
                      hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="1"></v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>
                      Title
                    </th>
                    <th>
                      ID
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in result.data"
                      :key="item.id"
                  >
                    <td class="text-left">{{ item.title }}</td>
                    <td class="text-left">{{ item.id }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row>
                <v-col sm="12">
                  <div class="text-center">
                    <v-pagination
                        v-model="result.current_page"
                        :length="result.last_page"
                        circle
                        @input="search(result.current_page)"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </v-card>
      </div>
    </div>
    <div v-else>
      <div>
        <q-b-header-mobile></q-b-header-mobile>
        <v-card color="#f2f3f7">
          <v-row>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                      v-model="words"
                      @input="search()"
                      outlined
                      placeholder="Write some words"
                      label="Search"
                      color="black"
                      hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>
                      Title
                    </th>
                    <th>
                      ID
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in result.data"
                      :key="item.id"
                  >
                    <td class="text-left">{{ item.title }}</td>
                    <td class="text-left">{{ item.id }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row>
                <v-col cols="12">
                  <div class="text-center">
                    <v-pagination
                        v-model="result.current_page"
                        :length="result.last_page"
                        circle
                        @input="search(result.current_page)"
                    ></v-pagination>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>

</template>

<script>
import QBHeader from "../components/QBHeader";
import QBHeaderMobile from "../components/QBHeaderMobile.vue";

export default {
  components: {
    QBHeaderMobile,
    QBHeader,
  },
  data() {
    return {
      screenType: null,
      words: '',
      result: [],
    }
  },
  mounted() {
    this.getScreenType()
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    search(page) {
      if (this.words.length % 2) {
        window.axios.post('api/exam/search?page=' + page, {
          words: this.words
        }).then((res) => {
          this.result = res.data.data
        })
      }
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

.big {
  font-size: 19px;
}

.icon-page-hover:hover {
  color: #39bf88
}

.icon-view-hover:hover {
  color: #f1803f !important;
}
</style>